import React, { useRef } from "react";
import { SERVER_HOST } from "../components/service/ApiService";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";

const pdfRegex = /^.*\.pdf$/;
const mp4Regex = /^.*\.mp4$/;
const DetailComponent = ({ detail }) => {
    const printableRef = useRef();
    const tools = ["pager", "spacer", "zoomInOut", "zoom", "selection", "spacer", "search", "print"];
    let filetype = "jpg";
    if (detail.filename) {
        if (pdfRegex.test(detail.filename)) {
            filetype = "pdf";
        }
        if (mp4Regex.test(detail.filename)) {
            filetype = "mp4";
        }
    }

    return (
        <div className="min-h-screen w-full mx-0 bg-white">
            <div className="mx-auto px-7 space-y-3 py-3">
                {detail.type === "01" && (
                    <>
                        <div className="text-center text-3xl font-medium">{detail.name}</div>
                        <div className="border my-3 border-govblue w-16 m-auto"></div>

                        <div>{detail.photo1 && detail.photo1 !== "null" && <img className="mx-auto md:w-1/2" src={SERVER_HOST + "/api/v1/image?type=GPA&id=" + detail.photo1} alt={detail.name} />}</div>
                        <div className="text-sm editor" ref={printableRef}>
                            <div style={{ maxWidth: "100vw" }} dangerouslySetInnerHTML={{ __html: detail.information }} />
                        </div>
                        {detail.photo2 && (
                            <div>
                                <img className="mx-auto md:w-1/2" src={SERVER_HOST + "/api/v1/image?type=GPA&id=" + detail.photo2} alt={""} />
                            </div>
                        )}
                    </>
                )}
                {detail.type === "02" && (
                    <div className="h-screen">
                        <iframe src={detail.information} sandbox="allow-scripts allow-same-origin" width="100%" height="100%" title={detail.name}></iframe>
                    </div>
                )}
                {detail.type === "03" && (
                    <>
                        <div className="text-center text-3xl font-medium">{detail.name}</div>
                        <div className="border my-3 border-govblue w-16 m-auto"></div>
                        {filetype === "pdf" && (
                            <PDFViewer
                                data={detail.information}
                                style={{
                                    height: "80vh",
                                }}
                                tools={tools}
                            />
                        )}

                        {filetype === "mp4" && (
                            <video controls className="mx-auto md:w-1/2">
                                <source src={`data:video/mp4;base64,${detail.information}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}

                        {filetype !== "mp4" && filetype !== "pdf" && <img src={`data:image/png;base64,${detail.information}`} alt="" className="mx-auto" />}
                    </>
                )}
            </div>
        </div>
    );
};

export default DetailComponent;
