import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SERVER_HOST, send, useFibaMsgBox, useLoading } from "../components";
import DetailComponent from "./DetailComponent";
const InformationList = () => {
    const { id } = useParams();
    const { showLoading } = useLoading();
    const { error } = useFibaMsgBox();
    const [informationList, setInformationList] = useState([]);
    const [detail, setDetail] = useState({});
    const [infoId] = useState(id);

    const getMenu = async () => {
        const res = await send(
            "mf000011",
            {
                id,
            },
            showLoading
        );

        if (res.status === "error") {
            error(res.message);
        } else {
            if (res.length === 1) {
                setDetail(res[0]);
            } else {
                setInformationList(res);
            }
        }
    };

    useEffect(() => {
        getMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoId]);

    return (
        <div className="w-full min-h-screen">
            <nav className="w-full mx-0 flex py-2 pl-5 sticky top-[125px] bg-white z-50 min-h-12" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-0">
                    <li>
                        <div>
                            <Link to={"/"} className=" font-bold">
                                Нүүр хуудас
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg className="h-5 w-5 flex-shrink-0 text-gray-300" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <div className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current={"page"}>
                                {detail.name}
                            </div>
                        </div>
                    </li>
                </ol>
            </nav>
            <div className="w-full min-h-screen mx-0 px-5 bg-white space-y-3 py-3">
                {informationList.length === 0 && <DetailComponent detail={detail} />}
                {informationList.length !== 0 && (
                    <div className="min-h-screen grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                        {informationList.map((file) => (
                            <InfoCard id={id} file={file} key={file.id} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

const InfoCard = ({ id, file }) => {
    return (
        <Link to={`/menu/${id}/${file.id}`} className="h-64 max-w-sm rounded-md overflow-hidden shadow-lg border-[1px] border-transparent hover:border-govblue bg-white">
            <div className="h-48 w-full overflow-hidden rouded-md bg-gray-200 group-hover:opacity-75">
                <img className="h-full w-full object-cover object-center" src={file.photo1 && file.photo1 !== "null" ? `${SERVER_HOST}/api/v1/image?type=GPA&id=${file.photo1}` : "/image/free-news.webp"} alt={file.name} />
            </div>
            <div className="h-20 w-full px-6 py-2">
                <div className="truncate text-lg font-medium text-gray-900">{file.name ?? ""}</div>
                <p className="truncate text-sm text-gray-500">{file.description}</p>
            </div>
        </Link>
    );
};

export default InformationList;
